import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Card } from '@components'

import SidebarMwrote from '@src/pagesShared/SidebarMwrote'

const CardStyled = styled(Card)`
  ${tw`max-w-sm`}
`

const SidebarDefault = ({ description, descriptionMyself }) => (
  <>
    <CardStyled variant="definition" title="Definition">
      <h4>
        バウハウシィファイ
        <br />
        [bauhausify]
      </h4>
      <p>{description}</p>
      <Link to="/2019-07-01_このブログについて/">詳しく見る</Link>
    </CardStyled>
    <SidebarMwrote descriptionMyself={descriptionMyself} />
  </>
)

export default SidebarDefault
