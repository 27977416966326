import React from 'react'
import SVGInline from 'react-svg-inline'
import styled from 'styled-components'

import { Card } from '@components'

import mwroteLogoIcon from '!raw-loader!@src/images/mwrote-logo-icon.svg'

const CardStyled = styled(Card)`
  ${tw`max-w-sm`}
`
const CardStyledTitle = styled.h4`
  ${tw`mb-5`}
`
const CardStyledIcon = styled.div`
  ${tw`w-100 flex justify-center items-center mt-2`}
`
const CardStyledDesc = styled.div`
  ${tw`mb-5 mt-5`}
`

const SidebarMwrote = ({ descriptionMyself }) => (
  <CardStyled variant="external">
    <CardStyledTitle>エムロート [Mwrote]</CardStyledTitle>
    <CardStyledIcon>
      <SVGInline width="57" height="91" svg={mwroteLogoIcon} />
    </CardStyledIcon>
    <CardStyledDesc dangerouslySetInnerHTML={{ __html: descriptionMyself }} />
  </CardStyled>
)

export default SidebarMwrote
